

















































































































































// TODO: Add validation for address length.

import {
  Vue, Component, PropSync, Watch,
} from 'vue-property-decorator';
import { VForm } from '@/vue-app/types/vuetify';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import AddressAllianzViewModel
  from '@/vue-app/view-models/components/contract-savings/personal-information/address/address-allianz-view-model';

// Domain
@Component({
  name: 'ContractSavingsPersonalInformationAddressAllianz',
  components: {
    CustomAutocomplete,
  },
})
export default class ContractSavingsPersonalInformationAddressAllianz extends Vue {
  @PropSync('addressForm', { type: Boolean })
  synced_address_form!: boolean;

  address_allianz_view_model = Vue.observable(new AddressAllianzViewModel());

  $refs!: {
    address_form: VForm;
  }

  @Watch('address_allianz_view_model.confirm_address')
  handleConfirmInformationChange(new_confirmation: boolean) {
    this.$emit('confirmAddress', new_confirmation);
  }

  async created() {
    this.address_allianz_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.address_allianz_view_model.initialize();
  }

  async saveStep() {
    const saved_step = await this.address_allianz_view_model.saveStep();
    return saved_step;
  }
}

