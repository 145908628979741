import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import { postalCodeFormat, requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';

// Application
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';

import {
  SearchSettlementsQuery,
} from '@/modules/my-investment/catalogs/allianz/settlement/application/queries';
import {
  SearchMunicipalitiesQuery,
} from '@/modules/my-investment/catalogs/allianz/municipality/application/queries';
import {
  SearchStatesQuery,
} from '@/modules/my-investment/catalogs/allianz/state/application/queries';
import {
  SearchCountriesQuery,
} from '@/modules/my-investment/catalogs/allianz/country/application/queries';
import {
  SearchAddressQuery,
} from '@/modules/my-investment/catalogs/kuspit/address/application/queries';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import SearchCustomerAddressQuery
  from '@/modules/my-investment/address/application/queries/search-address-query';

// Domain
import {
  SearchDto,
} from '@/modules/my-investment/catalogs/allianz/municipality/domain/dtos/search-dto';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  CustomerAddressEntity,
} from '@/modules/my-investment/address/domain/entities/customer-address-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import {
  SettlementEntity,
} from '@/modules/my-investment/catalogs/allianz/settlement/domain/entities/settlement-entity';
import {
  MunicipalityEntity,
} from '@/modules/my-investment/catalogs/allianz/municipality/domain/entities/municipality-entity';
import {
  StateEntity,
} from '@/modules/my-investment/catalogs/allianz/state/domain/entities/state-entity';
import {
  CountryEntity,
} from '@/modules/my-investment/catalogs/allianz/country/domain/entities/country-entity';
import i18n from '@/vue-app/plugins/i18n';

export default class AddressAllianzViewModel {
  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_SETTLEMENT_QUERY)
  readonly searchSettlementsQuery!: SearchSettlementsQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_MUNICIPALITY_QUERY)
  readonly searchMunicipalitiesQuery!: SearchMunicipalitiesQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_STATE_QUERY)
  readonly searchStatesQuery!: SearchStatesQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_COUNTRY_QUERY)
  readonly searchCountriesQuery!: SearchCountriesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_ADDRESS_QUERY)
  private readonly searchAddressQuery!: SearchAddressQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ADDRESS_QUERY)
  private readonly searchCustomerAddressQuery!: SearchCustomerAddressQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.personal-information.address';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  step_name = 'address';

  investment_provider_id = '';

  investment_provider_name = 'allianz';

  exists_step = false;

  small_screen: null | boolean = null;

  is_loading = true;

  confirm_address = false;

  settlement_items: SettlementEntity[] = [];

  municipalities_items: MunicipalityEntity[] = [];

  states_items: StateEntity[] = [];

  countries_items: CountryEntity[] = [];

  address = {
    street: '',
    external_number: '',
    interior_number: '',
    postal_code: '',
    settlement: {
      codPais: '',
      descPais: '',
      codEstado: '',
      descEstado: '',
      codLocalidad: '',
      codMunicipio: '',
      codigoPostal: '',
      descLocalidad: '',
      descMunicipio: '',
    },
    municipality: {
      codPais: '',
      codEstado: '',
      codMunicipio: '',
      descMunicipio: '',
    },
    state: {
      codestado: '',
      descestado: '',
      codpais: '',
    },
    country: {
      descpais: 'MÉXICO',
      codpais: '412',
    },
  };

  rules = {
    street: [requiredRule],
    external_number: [requiredRule],
    internal_number: [requiredRule],
    postal_code: [requiredRule, postalCodeFormat],
    settlement: [(value: string) => requiredRuleByKey(value, 'codLocalidad')],
    municipality: [(value: string) => requiredRuleByKey(value, 'codMunicipio')],
    state: [(value: string) => requiredRuleByKey(value, 'codestado')],
    country: [(value: string) => requiredRuleByKey(value, 'codpais')],
  };

  private on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  get settlements() {
    if (this.address.postal_code.length === 5) {
      return this.searchSettlementsQuery.execute(this.address.postal_code);
    }

    return [];
  }

  get municipalities() {
    if (this.address.country.codpais !== '' && this.address.state.codestado !== '') {
      const search: SearchDto = {
        codpais: this.address.country.codpais,
        codestado: this.address.state.codestado,
      };
      return this.searchMunicipalitiesQuery.execute(search);
    }
    return [];
  }

  get states() {
    return this.searchStatesQuery.execute(this.address.country.codpais);
  }

  get countries() {
    return this.searchCountriesQuery.execute();
  }

  get agreements() {
    return this.searchAgreementsAsyncQuery.execute();
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  get customerAddresses() {
    return this.searchCustomerAddressQuery.execute();
  }

  async changePostalCode() {
    const settlements = await this.settlements;
    const countries = await this.countries;
    if (settlements.length > 0) {
      this.settlement_items = settlements;
      this.countries_items = countries;
      const country = this.findOnArray('codpais', this.settlement_items[0].codPais, countries);
      if (country) {
        this.address.country = country;
        const states = await this.states;
        this.states_items = states;
        const state = this.findOnArray('codestado', this.settlement_items[0].codEstado, states);
        if (state) {
          this.address.state = state;
          const municipalities_list = await this.municipalities;
          this.municipalities_items = municipalities_list;
          const municipality = this.findOnArray('codMunicipio', this.settlement_items[0].codMunicipio, municipalities_list);
          if (municipality) {
            this.address.municipality = municipality;
          }
        }
      }
    }
  }

  findOnArray(key: string, value: string, items: Array<any>) {
    return items.find((item) => item[key] === value);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.address.street = this.on_boarding_step.payload.street;
    this.address.external_number = this.on_boarding_step.payload.exterior_number;
    this.address.interior_number = this.on_boarding_step.payload.interior_number;
    this.address.postal_code = this.on_boarding_step.payload.postal_code;
    this.address.country = {
      codpais: this.on_boarding_step.payload.country.codpais,
      descpais: this.on_boarding_step.payload.country.descpais,
    };
    this.address.state = {
      codestado: this.on_boarding_step.payload.state.codestado,
      descestado: this.on_boarding_step.payload.state.descestado,
      codpais: this.on_boarding_step.payload.state.codpais,
    };
    this.address.municipality = {
      codEstado: this.on_boarding_step.payload.municipality.codEstado,
      codMunicipio: this.on_boarding_step.payload.municipality.codMunicipio,
      codPais: this.on_boarding_step.payload.municipality.codPais,
      descMunicipio: this.on_boarding_step.payload.municipality.descMunicipio,
    };
    this.address.settlement = {
      codEstado: this.on_boarding_step.payload.settlement.codEstado,
      codLocalidad: this.on_boarding_step.payload.settlement.codLocalidad,
      codMunicipio: this.on_boarding_step.payload.settlement.codMunicipio,
      codPais: this.on_boarding_step.payload.settlement.codPais,
      codigoPostal: this.on_boarding_step.payload.settlement.codigoPostal,
      descEstado: this.on_boarding_step.payload.settlement.descEstado,
      descLocalidad: this.on_boarding_step.payload.settlement.descLocalidad,
      descMunicipio: this.on_boarding_step.payload.settlement.descMunicipio,
      descPais: this.on_boarding_step.payload.settlement.descPais,
    };
    this.confirm_address = this.on_boarding_step.payload.confirm_address_info;
  };

  setInputsValues = (customer_addresses: Array<CustomerAddressEntity>) => {
    if (customer_addresses.length) {
      const address_default = customer_addresses.find(
        (address) => address.customer_address_default,
      );
      if (address_default) {
        this.address.street = address_default.address.street;
        this.address.external_number = address_default.address.external_number;
        this.address.interior_number = address_default.address.interior_number || '';
        this.address.postal_code = address_default.address.zip_code;
      }
    }
  };

  async saveStep() {
    this.is_loading = true;
    const steps = await this.steps;
    const search_step = steps.find(
      (step) => step.current_step === this.step_name,
    );
    try {
      this.on_boarding_step.payload.state = this.address.state;
      this.on_boarding_step.payload.street = this.address.street;
      this.on_boarding_step.payload.country = this.address.country;
      this.on_boarding_step.payload.settlement = this.address.settlement;
      this.on_boarding_step.payload.postal_code = this.address.postal_code;
      this.on_boarding_step.payload.municipality = this.address.municipality;
      this.on_boarding_step.payload.exterior_number = this.address.external_number;
      this.on_boarding_step.payload.interior_number = this.address.interior_number;
      this.on_boarding_step.payload.confirm_address_info = true;
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      if (search_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const customer_addresses = await this.customerAddresses;
    this.setInputsValues(customer_addresses);

    const steps = await this.steps;
    await this.verifyStep(steps);

    await this.changePostalCode();

    this.is_loading = false;
  }
}
